<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		id="modal-replace"
		v-model="isOpen"
		hide-footer
		no-close-on-backdrop
		no-close-on-esc
		@hidden="emitCloseEvent"
		modal-class="mui-animation"
		:fade="false"
	>
		<template #modal-title>
			<span v-if="forUser">
				{{ FormMSG(21, 'Replaced by') }}
			</span>
			<span v-else>
				{{ FormMSG(22, 'Replaced by') }}
			</span>
		</template>
		<div>
			<b-row v-if="isForUser">
				<b-col>
					<list-users
						ref="replaceModalListUsers"
						key="replace_modal_list_users"
						:users="listUsers"
						:array-task="taskSelected"
						:single-data-selected="dataUserSelected"
						is-single-select
						@list-users:selected="handleListUsersSelected"
					/>
				</b-col>
			</b-row>
			<b-row v-else>
				<b-col>
					<list-teams
						ref="replaceModalListTeams"
						key="replace_modal_list_teams"
						:teams="listTeams"
						:array-task="taskSelected"
						:single-data-selected="dataTeamSelected"
						is-single-select
						@list-teams:selected="handleListTeamsSelected"
					/>
				</b-col>
			</b-row>
			<b-row class="mt-3">
				<b-col>
					<div class="float-right">
						<b-button class="mr-2" @click="hideModal">
							{{ FormMSG(3, 'Close') }}
						</b-button>
						<b-button variant="success" :disabled="enableBtnSaved" @click="emitEvent">
							{{ FormMSG(4, 'Save') }}
						</b-button>
					</div>
				</b-col>
			</b-row>
		</div>
	</b-modal>
</template>

<script>
import ListUsers from './ListUsers';
import ListTeams from './ListTeams';
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'ReplaceModal',

	components: {
		ListTeams,
		ListUsers
	},

	mixins: [languageMessages],

	props: {
		open: { type: Boolean, default: false },
		isForUser: { type: Boolean, default: false },
		users: { type: Array, default: () => [] },
		teams: { type: Array, default: () => [] },
		userSelected: { type: Object, default: () => null },
		teamSelected: { type: Object, default: () => null },
		taskSelected: { type: Array, default: () => [] },
		dataUserSelected: { type: Object, default: null },
		dataTeamSelected: { type: Object, default: null }
	},

	data() {
		return {
			newUserSelected: null,
			newTeamSelected: null,
			listTeams: [],
			listUsers: []
		};
	},

	computed: {
		enableBtnSaved() {
			if (this.newUserSelected !== null || this.newTeamSelected !== null) {
				return false;
			}
			return true;
		},
		forUser: {
			get() {
				return this.isForUser;
			},
			set(val) {
				return val;
			}
		},
		isOpen: {
			get() {
				return this.open;
			},
			set(val) {
				return val;
			}
		}
	},

	watch: {
		userSelected: {
			handler(val) {
				this.listUsers = this.users.filter((u) => parseInt(u.id, 10) !== parseInt(val.id, 10));
			},
			immediate: true
		},
		teamSelected: {
			handler(val) {
				this.listTeams = this.teams.filter((t) => parseInt(t.id, 10) !== parseInt(val.id, 10));
			},
			immediate: true
		},
		dataUserSelected: {
			handler(val) {
				this.newUserSelected = val;
			},
			immediate: true
		},
		dataTeamSelected: {
			handler(val) {
				this.newTeamSelected = val;
			},
			immediate: true
		}
	},

	methods: {
		handleListTeamsSelected(payload) {
			this.newTeamSelected = payload;
		},
		handleListUsersSelected(payload) {
			this.newUserSelected = payload;
		},
		emitEvent() {
			if (this.forUser) {
				this.$emit('replace-modal:new-user-selected', this.newUserSelected.usersSelected);
			} else {
				this.$emit('replace-modal:new-team-selected', this.newTeamSelected.teamsSelected);
			}
		},
		emitCloseEvent() {
			this.$emit('replace-modal:close');
		},
		hideModal() {
			this.emitCloseEvent();
			this.$bvModal.hide('modal-replace');
		}
	}
};
</script>
